import React from 'react'
import PropTypes from 'prop-types'
import CallToAction from '../../../shared/CallToActionButton'
import SvgIcon from '../../../shared/SvgIcon'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { featureToggles } from 'src/common/constants/featureToggles'

const CreditCardDebtSimple = ({
  title,
  debtTitle,
  debtText,
  saveTitle,
  saveText,
  enrollTitle,
  enrollText,
  footer,
  button,
}) => {
  const { getExperimentVariation } = useFeatureFlags()
  const homepageDropDownFloatingFooter = getExperimentVariation(
    experimentSectionByVariation[
      featureToggles.HOMEPAGE_DROP_DOWN_FLOATING_FOOTER
    ]
  )
  const isHomepageTestOn = homepageDropDownFloatingFooter === 'test'

  return (
    <div
      className="lg:w-content m-auto bg-gray-130"
      id="credit-card-debt-simple"
    >
      <div className="w-block text-center lg:max-w-screen-lg">
        <h2 className="mb-3 text-center text-2xl font-normal leading-tight tracking-normal text-blue-700 md:text-4xl md:leading-10">
          {title}
        </h2>
        <div className="mt-6 flex flex-col items-stretch md:flex-row">
          <div className="flex w-auto flex-col md:w-1/3 md:p-6">
            <SvgIcon
              name="bankCards"
              className="m-auto mb-3 h-12 w-13 fill-blue-700"
            />
            <p className="m-auto mt-5 text-center text-lg font-semibold leading-none tracking-normal text-blue-700">
              {debtTitle}
            </p>
            <p className="m-auto mt-4 h-32 text-center text-sm font-normal leading-snug tracking-normal text-gray-900">
              {debtText}
            </p>
          </div>
          <div className="flex w-auto flex-col md:w-1/3 md:p-6">
            <SvgIcon
              name="magnifyingGlass"
              className="m-auto mb-3 h-12 w-12 fill-blue-700"
            />
            <p className="m-auto mt-5 text-center text-lg font-semibold leading-none tracking-normal text-blue-700">
              {saveTitle}
            </p>
            <p className="m-auto mt-4 h-32 text-center text-sm font-normal leading-snug tracking-normal text-gray-900">
              {saveText}
            </p>
          </div>
          <div className="flex w-auto flex-col md:w-1/3 md:p-6">
            <SvgIcon
              name="document"
              className="m-auto mb-3 h-12 w-12 fill-blue-700"
            />
            <p className="m-auto mt-5 text-center text-lg font-semibold leading-none tracking-normal text-blue-700">
              {enrollTitle}
            </p>
            <p className="m-auto mt-4 h-32 text-center text-sm font-normal leading-snug tracking-normal text-gray-900">
              {enrollText}
            </p>
          </div>
        </div>
        <p
          className="text-sm font-normal leading-snug tracking-normal text-gray-900"
          dangerouslySetInnerHTML={{ __html: footer }}
        />
        {!isHomepageTestOn && (
          <div className="m-auto flex w-5/6 justify-center md:w-3/4">
            <CallToAction
              id="credit-card-debt-simple-cta"
              title={title}
              fontClassName="text-lg font-normal leading-snug tracking-normal text-white"
              className="mt-10"
              text={button.text}
              isLeadId={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

CreditCardDebtSimple.defaultProps = {
  footer: '',
}

CreditCardDebtSimple.propTypes = {
  title: PropTypes.string.isRequired,
  debtTitle: PropTypes.string.isRequired,
  debtText: PropTypes.string.isRequired,
  saveTitle: PropTypes.string.isRequired,
  saveText: PropTypes.string.isRequired,
  enrollTitle: PropTypes.string.isRequired,
  enrollText: PropTypes.string.isRequired,
  footer: PropTypes.string,
  button: PropTypes.object.isRequired,
}

export default CreditCardDebtSimple
