export const paymentsReferenceTable = {
  /*
    [amount]: [
      otherMonthlyPayment,
      otherTotalCost,
      debtReliefMonthlyPayment,
      debtReliefTotalCost
    ]
  */
  5000: [145, 8208.95, 128, 5319],
  6000: [174, 9845, 134, 6001],
  7000: [203, 11487, 158, 6995],
  8000: [232, 13127, 176, 7561],
  9000: [261, 14768, 188, 8274],
  10000: [290.33, 16409.01, 198, 9480],
  15000: [435.5, 24613.32, 298, 14280],
  20000: [580.67, 32817.55, 378, 18130],
  25000: [725.84, 41021.87, 470, 22543],
  30000: [871, 49226.6, 549, 26355],
  35000: [1016.17, 57431.03, 639, 30668],
  40000: [1161.34, 65635.22, 729, 34980],
  45000: [1306.5, 73839.96, 873, 41880],
  50000: [1451.67, 82044.26, 908, 43605],
  55000: [1596.84, 90248.55, 1064, 51080],
  60000: [1742.01, 98452.83, 1088, 52230],
  65000: [1887.17, 106657.6, 1256, 60280],
  70000: [2032.34, 114861.89, 1268, 60855],
  75000: [2177.51, 123066.18, 1342, 64418],
  80000: [2322.67, 131270.93, 1431, 68680],
  85000: [2467.84, 139475.23, 1639, 78680],
  90000: [2613.01, 147679.56, 1608, 77205],
  95000: [2758.18, 155883.78, 1831, 87880],
  100000: [2903.34, 164088.52, 1744, 83730],
  105000: [3048.51, 172292.85, 1936, 81720],
  110000: [3193.68, 180497.13, 2032, 97952],
  115000: [3338.84, 188701.78, 2152, 77837],
  120000: [3484.01, 196906.24, 2276, 109714],
  125000: [3629.18, 205110.52, 3348, 109433],
  130000: [3774.35, 213314.79, 3390, 122407],
  135000: [3919.51, 221519.48, 2744, 112238],
  140000: [4064.68, 229723.79, 2318, 111684],
  145000: [4209.85, 237928.04, 3884, 140140],
  150000: [4355.01, 246132.85, 2892, 138839],
}

export const estimatedPaymentsReferenceTable = {
  5000: [2074, 23],
  6000: [2243, 29],
  7000: [2464, 35],
  8000: [2565, 43],
  9000: [2765, 47],
  10000: [2965, 53],
  15000: [3842, 68],
  20000: [5418, 96],
  25000: [6975, 123],
  30000: [8529, 152],
  35000: [10084, 179],
  40000: [11664, 207],
  45000: [13226, 235],
  50000: [14791, 263],
  55000: [16339, 290],
  60000: [17912, 317],
  65000: [19457, 346],
  70000: [21031, 373],
  75000: [22628, 401],
  80000: [24158, 428],
  85000: [25710, 456],
  90000: [27300, 484],
  95000: [28834, 511],
  100000: [30391, 540],
  105000: [31451, 568],
  110000: [3233, 596],
  115000: [3438, 624],
  120000: [3484.01, 652],
  125000: [3629.18, 680],
  130000: [3774.35, 708],
  135000: [3919.51, 736],
  140000: [4064.68, 854],
  145000: [4209.85, 882],
  150000: [4355.01, 910],
}
